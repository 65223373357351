<script setup lang="ts">
import type { TableColumnType, TablePaginationConfig } from 'ant-design-vue'
import type { PaginationConfig } from 'ant-design-vue/es/pagination'
import type { PropType } from 'vue'

const props = defineProps({
  data: { type: Array<EmptyObjectType>, default: () => [] },
  columns: { type: Array<TableColumnType>, default: () => [] },
  config: { type: Object as PropType<TableConfig>, default: () => { } },
  search: { type: Array<TableSearchType>, default: () => [] },
  pagination: { type: [Boolean, Object as TablePaginationConfig], default: () => { } },
  needHeader: { type: Boolean, default: false },
  x: { type: Number, default: undefined },
})

// 定义子组件向父组件传值/事件
const emit = defineEmits<{
  pageChange: [current: number, pageSize: number]
}>()

const state = reactive({
  page: {
    pageNum: 1,
    pageSize: 20,
  },
  selectlist: [] as EmptyObjectType[],
  checkListAll: true,
  checkListIndeterminate: false,
})

// 设置边框显示/隐藏
const setBorder = computed(() => !!props.config?.isBorder)

// 搜索时，分页还原成默认
function pageReset() {
  state.page.pageNum = 1
  state.page.pageSize = 10
  emit('pageChange', state.page)
}

// 刷新

// 翻页/改变每页展示数量
function onPageChange(page: TablePaginationConfig) {
  const { current, pageSize } = page
  emit('pageChange', pageSize === (props.pagination as PaginationConfig).pageSize ? current ?? 1 : 1, pageSize ?? 20)
}

const scrollY = computed(() => {
  if (props.pagination === false || Object.keys(props.pagination).length === 0) {
    return 'calc(100% - 39px)'
  }
  else {
    return 'calc(100% - 39px)'
  }
})

const localPagination = computed(() => {
  if (Object.keys(props.pagination).length === 0) {
    return false
  }
  return props.pagination
})

// 暴露变量
defineExpose({
  pageReset,
})
</script>

<template>
  <div flex-1 class="base-table-container">
    <a-table
      size="small" :data-source="data" :columns="columns" :pagination="localPagination" :loading="config.loading"
      :bordered="setBorder" v-bind="$attrs" :scroll="{ scrollToFirstRowOnChange: true, y: scrollY, x: 300 }"
      @change="onPageChange"
    >
      <template #headerCell="slotProp">
        <slot name="headerCell" v-bind="slotProp" />
      </template>
      <template v-if="needHeader" #title>
        <slot name="header" />
      </template>
    </a-table>
  </div>
</template>

<style lang="scss" scoped>
.base-table-container {
  height: 100%;
  overflow: hidden;
}

:deep(.ant-table-wrapper) {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .ant-table {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 0;

        .ant-table-container {
          flex: 1;
          overflow: hidden;
          border-start-start-radius: 0;
          border-start-end-radius: 0;

          .ant-table-header{
            border-radius: 0;
            table{
              border-radius: 0px;
              .ant-table-thead{
                &>tr:first-child>*:first-child{
                  border-start-start-radius: 0px;
                }

                &>tr>th.ant-table-cell{
                  border-radius: 0;
                  padding-left: 24px;
                }
                &>tr>th.ant-table-selection-column{
                  padding: 8px 8px;
                }
              }
            }
          }
          .ant-table-body{
            scrollbar-width: auto;
            scrollbar-color: auto;
            height: calc(100% - 39px);
            table{
              .ant-table-tbody{
                &>tr>td.ant-table-cell{
                  // 单元格加左padding
                  padding-left:24px;
                }
                &>tr>td.ant-table-selection-column{
                  padding: 8px 8px;
                }

                &>:last-child>td{
                  // 去除表格最下方的横线
                  border-bottom: none;
                }
              }
            }
          }

        }
      }

      .ant-pagination {
        padding: 16px 20px;
        margin: 0;
        border-top: 1px solid #e6e6e6;

        &>li:first-child {
          flex: 1;
          text-align: left;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(219, 219, 219);
  }

}
</style>
